/* eslint-disable react/no-array-index-key */
import React from 'react'

// Third party
import { useStaticQuery, graphql } from 'gatsby'
import styled from 'styled-components'

// Elements
import NavLink from 'components/shared/NavLink'

// Images
import Logo from 'img/logo.inline.svg'
import ParseContent from 'components/shared/ParseContent'

const StyledFooter = styled.div`
  background-color: ${(props) => props.theme.color.dark};
  color: ${(props) => props.theme.color.light};

  & * {
    font-weight: ${({ theme }) => theme.font.weight.semiBold} !important;
  }

  & h2 {
    font-size: ${({ theme }) => theme.font.size.medium};
  }

  & a {
    color: ${({ theme }) => theme.color.light};
    font-size: ${({ theme }) => theme.font.size.small};
    text-decoration: none;
    line-height: 24px;
  }

  & a:hover {
    color: ${({ theme }) => theme.color.light};
    text-decoration: underline;
  }
`

const Brand = styled(NavLink)`
  & > svg {
    height: 51.37px;
    width: 252px;
  }
`

const Footer = () => {
  // eslint-disable-next-line
  const { fields } = useStaticQuery<GatsbyTypes.footerQueryQuery>(graphql`
    query footerQuery {
      fields: wpComponent(databaseId: { eq: 98 }) {
        footer {
          menuFooter {
            columnleft {
              title
              menu {
                link {
                  title
                  url
                }
              }
            }
            columnright {
              title
              menu {
                link {
                  title
                  url
                }
              }
            }
          }
          infoFooter {
            info
            socials {
              icon {
                localFile {
                  publicURL
                }
              }
              link {
                title
                url
              }
            }
          }
        }
      }
    }
  `)

  const { info, socials }: any = fields?.footer?.infoFooter

  const { columnleft, columnright }: any = fields?.footer?.menuFooter

  return (
    <StyledFooter>
      <div className="container py-5">
        <div className="row">
          <div className="col-lg-4">
            <Brand to="/">
              <Logo />
            </Brand>
            <ParseContent content={info} className="mt-5" />
            {/* <Socials className="d-flex mt-4">
              {socials.map((social: any, index: number) => (
                <a
                  href={social.link.url}
                  target="_blank"
                  rel="noreferrer"
                  key={index}
                  className="me-3"
                >
                  <Plaatjie image={social.icon} alt="" />
                  <span className="text-hidden">{social.link.title}</span>
                </a>
              ))}
            </Socials> */}
          </div>
          <div className="col-lg-8">
            <div className="row">
              <div className="col-lg-4">
                <h2 className="mt-5 mb-4">{columnleft.title}</h2>
                {columnleft.menu.map((item: any, index: number) => (
                  <div className="mb-2" key={index}>
                    <NavLink to={item.link.url}>{item.link.title}</NavLink>
                  </div>
                ))}
              </div>
              <div className="col-lg-4">
                <h2 className="mt-5 mb-4">{columnright.title}</h2>
                <div
                  className="d-flex flex-column flex-wrap"
                  style={{ height: 150 }}
                >
                  {columnright.menu.map((item: any, index: number) => (
                    <div className="mb-2" key={index} style={{ width: 200 }}>
                      <NavLink to={item.link.url}>{item.link.title}</NavLink>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </StyledFooter>
  )
}

export default Footer
