import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled from 'styled-components'

const StyledDesktop = styled.ul`
  list-style: none;
  margin-bottom: 0;

  @media (min-width: 1440px) {
    & li {
      margin-left: 40px;
    }
  }

  @media (max-width: 1439px) {
    & li {
      margin-left: 20px;
    }
  }
`

interface DesktopProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
}

const Desktop: React.FC<DesktopProps> = ({ fields, isSticky }) => (
  <StyledDesktop className="d-none d-lg-flex align-items-center">
    <Menu fields={fields} />
  </StyledDesktop>
)

export default Desktop
