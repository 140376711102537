/* eslint-disable import/no-unresolved */
import React from 'react'

// Third party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'
import { useStaticQuery, graphql } from 'gatsby'

// Images
import Logo from 'img/logo.inline.svg'

// Components
import NavLink from 'components/shared/NavLink'
import Mobile from 'components/layout/Header/Mobile'
import Desktop from 'components/layout/Header/Desktop'

const StyledHeader = styled(motion.nav)<{
  isSticky: boolean
  staticHeader: boolean
}>`
  background-color: transparent;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 125px;
  z-index: 10;

  ${(props) =>
    props.isSticky &&
    css`
      background: rgba(38, 38, 38, 0.9);
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.3);
      backdrop-filter: blur(2px);
    `}

  ${(props) =>
    !props.isSticky &&
    css`
      background: linear-gradient(
        180deg,
        #262626 3.82%,
        rgba(38, 38, 38, 0) 79.1%
      );
    `}

    ${(props) =>
    props.staticHeader &&
    css`
      box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
      background-color: rgba(38, 38, 38, 0.9);
    `}
`

const Container = styled.div<{ isSticky: boolean }>`
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;

  ${(props) =>
    !props.isSticky &&
    css`
      &:before {
        position: absolute;
        content: '';
        bottom: 10px;
        height: 1px;
        background-color: white;
      }

      &:after {
        position: absolute;
        content: '';
        bottom: 0;
        height: 1px;
        background-color: white;
      }

      @media (min-width: 992px) {
        &:before {
          width: 98.2%;
        }

        &:after {
          width: 98.2%;
        }
      }

      @media (max-width: 991px) {
        &:before {
          width: 93.2%;
        }

        &:after {
          width: 93.2%;
        }
      }

      @media (max-width: 767px) {
        &:before {
          width: 91.2%;
        }

        &:after {
          width: 91.2%;
        }
      }
    `}
`

const Brand = styled(NavLink)`
  &:hover {
    opacity: 0.85;
  }

  @media (min-width: 1440px) {
    & > svg {
      height: 51.37px;
      width: 252px;
    }
  }

  @media (max-width: 1439px) {
    & > svg {
      height: 41.37px;
      width: 182px;
    }
  }
`

interface HeaderProps {
  setIsScrollable: Function
  isSticky?: boolean
  staticHeader?: boolean
}

const Header: React.FC<HeaderProps> = ({
  setIsScrollable,
  isSticky = false,
  staticHeader,
}) => {
  // eslint-disable-next-line
  const query = useStaticQuery<GatsbyTypes.headerQueryQuery>(graphql`
    query headerQuery {
      wpComponent(databaseId: { eq: 97 }) {
        header {
          menuHeader {
            link {
              title
              url
            }
            submenu {
              link {
                title
                url
              }
              image {
                localFile {
                  childImageSharp {
                    gatsbyImageData
                  }
                }
              }
            }
            highlighted
          }
        }
      }
    }
  `)

  const fields: any = query.wpComponent

  return (
    <StyledHeader
      isSticky={isSticky}
      initial={{ height: 125 }}
      animate={isSticky ? { height: 80 } : { height: 125 }}
      staticHeader={staticHeader}
    >
      <Container className="container" isSticky={isSticky}>
        <Brand to="/">
          <Logo />
        </Brand>

        <Mobile
          fields={fields}
          setIsScrollable={setIsScrollable}
          isSticky={isSticky}
        />
        <Desktop fields={fields} isSticky={isSticky} />
      </Container>
    </StyledHeader>
  )
}

export default Header
