import React from 'react'

// Components
import Menu from 'components/layout/Header/Menu'

// Third Party
import styled from 'styled-components'

// Images
import Spanish from 'img/spanish.png'
import NatureStone from 'img/naturestone.png'

const MenuWrapper = styled.div`
  background-color: ${(props) => props.theme.color.primary};
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 99;
  display: flex;
  justify-content: center;
  align-items: center;

  & ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
    width: 70%;
  }
`

const NavButtonClose = styled.div`
  position: absolute;
  right: 20px;
  top: 20px;
  text-align: center;
`

const NavButtonCloseCross = styled.div`
  position: relative;
  width: 32px;
  height: 32px;
  margin-left: 16px;

  &:before,
  &:after {
    background-color: ${({ theme }) => theme.color.dark};

    position: absolute;
    content: ' ';
    height: 33px;
    width: 4px;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }
`

const NavButton = styled.button`
  width: 80px;
  height: 50px;
  padding: 0 10px;
  text-align: center;
  z-index: 10;
`

const NavButtonStripe = styled.div`
  background: url(${Spanish});
  background-size: 4px;
  height: 4px;
  width: 50%;
  margin: 5px auto;
`

interface MobileProps {
  fields: GatsbyTypes.WpComponent
  isSticky?: boolean
  setIsScrollable: Function
}

const Mobile: React.FC<MobileProps> = ({ fields, setIsScrollable }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  return (
    <div className="d-block d-lg-none">
      <NavButton
        role="button"
        onClick={() => {
          setIsOpen(!isOpen)
          setIsScrollable(false)
        }}
      >
        <NavButtonStripe />
        <NavButtonStripe />
        <NavButtonStripe />
      </NavButton>
      {isOpen && (
        <MenuWrapper>
          <NavButtonClose
            role="button"
            onClick={() => {
              setIsOpen(!isOpen)
              setIsScrollable(true)
            }}
          >
            <NavButtonCloseCross />
          </NavButtonClose>
          <ul>
            <Menu fields={fields} />
          </ul>
        </MenuWrapper>
      )}
    </div>
  )
}

export default Mobile
