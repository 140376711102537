/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import { Link } from 'gatsby'
import parse, { domToReact } from 'html-react-parser'
import styled from 'styled-components'

// Services
import convertStyle from 'services/convertStyle'

// Elements
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'
import SpanItalic from 'components/elements/Headings/SpanItalic'
import ButtonSecondary from 'components/elements/Buttons/ButtonSecondary'
import ButtonPrimaryTransparent from 'components/elements/Buttons/ButtonPrimaryTransparent'
import HeadingItalic from 'components/elements/Headings/HeadingItalic'

const Content = styled.div`
  font-weight: ${(props) => props.theme.font.weight.light};
  font-size: ${({ theme }) => theme.font.size.medium};
  color: ${({ theme }) => theme.color.dark};

  & strong,
  & b {
    font-weight: ${(props) => props.theme.font.weight.bold};
  }

  & h1,
  & h2,
  & h3,
  & h4,
  & h5 {
    text-align: initial;
    font-weight: ${(props) => props.theme.font.weight.light};

    & strong,
    & b {
      font-weight: ${(props) => props.theme.font.weight.bold};
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & h1 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.mega};
      line-height: ${({ theme }) => theme.font.size.mega};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }
  }

  & h2 {
    @media (min-width: 992px) {
      font-size: 30px;
      line-height: 52px;
    }

    @media (max-width: 991px) {
      font-size: 24px;
      line-height: 38px;
    }
  }

  & h3,
  & h4,
  & h5 {
    @media (min-width: 992px) {
      font-size: ${({ theme }) => theme.font.size.huge};
      line-height: ${({ theme }) => theme.font.size.huge};
    }

    @media (max-width: 991px) {
      font-size: ${({ theme }) => theme.font.size.large};
      line-height: ${({ theme }) => theme.font.size.large};
    }
  }

  & ul {
    & li {
    }
  }

  & p {
    @media (min-width: 992px) {
      font-size: 16px;
      line-height: 24px;
    }

    @media (max-width: 991px) {
      font-size: 16px;
      line-height: 20px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  & a {
    color: ${({ theme }) => theme.color.secondary};
    text-decoration: underline;
    transition: all 0.3s ease;

    &:hover {
      color: ${({ theme }) => theme.color.primary};
      text-decoration: underline;
    }
  }

  & img {
  }

  /*
    WP WYSIWYG
  */
  .alignright {
    float: right;
    text-align: right;
  }

  .aligncenter {
    display: block;
    margin: 0 auto;
    text-align: center;
  }

  .wp-caption {
    max-width: 100%;
  }

  .wp-caption-text {
    font-size: ${({ theme }) => theme.font.size.tiny};
    font-weight: ${({ theme }) => theme.font.weight.bold};
    text-align: center;
  }
`

const findAndReplace = (content: string) =>
  parse(content, {
    replace: (domNode) => {
      const { name, attribs, children }: any = domNode

      if (name === 'span' && attribs.class === 'heading-italic') {
        return <SpanItalic>{domToReact(children, {})}</SpanItalic>
      }

      if (
        (name === 'h1' && attribs.class === 'heading-italic') ||
        (name === 'h2' && attribs.class === 'heading-italic')
      ) {
        return <HeadingItalic>{domToReact(children, {})}</HeadingItalic>
      }
      if (name === 'a' && attribs.class === 'button-primary') {
        return (
          <ButtonPrimary to={attribs.href} target={attribs.target || ''}>
            {domToReact(children, {})}
          </ButtonPrimary>
        )
      }

      if (name === 'a' && attribs.class === 'button-primary-transparent') {
        return (
          <ButtonPrimaryTransparent
            to={attribs.href}
            target={attribs.target || ''}
          >
            {domToReact(children, {})}
          </ButtonPrimaryTransparent>
        )
      }

      if (name === 'a' && attribs.class === 'button-secondary') {
        return (
          <ButtonSecondary to={attribs.href} target={attribs.target || ''}>
            {domToReact(children, {})}
          </ButtonSecondary>
        )
      }

      if (name === 'a') {
        const attributes = attribs

        if (attributes.style) {
          attributes.style = convertStyle(attributes.style)
        }

        if (
          attributes.href.indexOf('www.') !== -1 ||
          attributes.href.indexOf('http:') !== -1 ||
          attributes.href.indexOf('https:') !== -1 ||
          attributes.href.indexOf('tel:') !== -1 ||
          attributes.href.indexOf('mailto:') !== -1
        ) {
          return <a {...attributes}>{domToReact(children, {})}</a>
        }

        return (
          <Link to={attributes.href} {...attributes}>
            {domToReact(children, {})}
          </Link>
        )
      }

      return null
    },
  })

interface ParseContentProps {
  content?: string
  className?: string
}

const ParseContent: React.FC<ParseContentProps> = ({
  content,
  className = '',
}) => {
  if (!content) {
    return null
  }

  return <Content className={className}>{findAndReplace(content)}</Content>
}

export default ParseContent
