import React from 'react'

// Third party
import styled, { css } from 'styled-components'

// Elements
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Images
import Calendar from 'img/calendar.inline.svg'

export const ButtonPrimaryTransparentCSS = css`
  position: relative;
  background-color: transparent;
  display: inline-block;
  border: 2px solid ${({ theme }) => theme.color.light};
  overflow: hidden;

  & > a,
  & > button {
    position: relative;
    color: ${(props) => props.theme.color.light} !important;
    padding: 10px 15px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none !important;
  }

  & svg {
    margin-right: 8px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 130%;
    height: 100%;
    background: ${({ theme }) => theme.color.primary};
    transform-origin: 0 0;
    transform: translateX(-100%) skewX(-45deg);
    transition: transform 0.65s;
  }

  &:hover {
    & > a,
    & > button,
    & span {
      color: ${({ theme }) => theme.color.dark} !important;
    }

    & svg path {
      fill: ${({ theme }) => theme.color.dark};
    }

    &:before {
      transform: translateX(0) skewX(-45deg);
    }
  }
`

const StyledButton = styled.span`
  ${ButtonPrimaryTransparentCSS};
`

interface ButtonPrimaryTransparentProps {
  isAnchor?: boolean
  isCustom?: boolean
  to: string
  className?: string
  target?: string
}

const ButtonPrimaryTransparent: React.FC<ButtonPrimaryTransparentProps> = ({
  isAnchor = false,
  isCustom = false,
  to,
  children,
  className = '',
  target = '',
}) => (
  <StyledButton className={className}>
    {isCustom ? (
      <>
        <Calendar />
        {children}
      </>
    ) : (
      <ButtonShell to={to} isAnchor={isAnchor} target={target}>
        <Calendar />
        {children}
      </ButtonShell>
    )}
  </StyledButton>
)

export default ButtonPrimaryTransparent
