/* eslint-disable react/no-array-index-key */
import React, { useState } from 'react'

// Third Party
import styled from 'styled-components'
import { AnimatePresence, motion } from 'framer-motion'
import Plaatjie from '@ubo/plaatjie'

// Components
import NavLink from 'components/shared/NavLink'

// Images
import Chevron from 'img/chevronright.inline.svg'

interface SubmenuProps {
  // eslint-disable-next-line
  fields: readonly GatsbyTypes.Maybe<GatsbyTypes.WpComponent_Header_menuHeader_submenu>[]
}

const Item = styled(motion.li)`
  list-style: none;
  margin-bottom: 20px;

  & > a {
    color: ${(props) => props.theme.color.secondary} !important;

    &[aria-current] {
    }

    &:hover {
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }
  }

  & svg path {
    fill: ${({ theme }) => theme.color.secondary};
  }

  @media screen and (max-width: 991px) {
    padding: 4px 0;

    & > a {
      font-size: 16px;
      line-height: 16px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.light};
    }
  }
`

const StyledSubmenu = styled(motion.div)`
  overflow: hidden;

  @media screen and (min-width: 992px) {
    position: absolute;
    top: 20px;
    overflow: hidden;
    z-index: 2;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    left: -300px;
    background-color: ${({ theme }) => theme.color.primary};
  }

  @media screen and (max-width: 991px) {
    position: absolute;

    z-index: 1;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 6px -1px,
      rgba(0, 0, 0, 0.06) 0px 2px 4px -1px;
    padding-top: unset;
    width: 100%;
    background-color: ${({ theme }) => theme.color.light};
    transform: translateZ(2px);

    & > div {
      padding: 2px 5px 2px 10px;
    }
  }
`

const Menu = styled.div`
  padding: 2.5rem 1.5rem;
`

const ImageWrapper = styled.div`
  position: relative;
  width: 370px;
  height: 250px;
`

const Image = styled(Plaatjie)`
  position: absolute !important;
  width: 100%;
  height: 100%;
  right: 0;
`

const Inner = styled.div`
  min-width: 400px;

  @media (min-width: 992px) {
    height: 200px;
  }

  @media (max-width: 991px) {
    overflow-y: scroll;
    height: 225px;
  }
`

const Submenu: React.FC<SubmenuProps> = ({ fields }) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0)

  const imageAnimations = {
    collapsed: {
      height: 0,
      opacity: 0,
    },
    open: {
      height: '100%',
      opacity: 1,
    },
  }

  return (
    <StyledSubmenu
      initial={{ height: 0 }}
      animate={{ height: 'fit-content' }}
      exit={{ height: 0 }}
      transition={{ type: 'tween', stiffness: 10, duration: 0.2 }}
      className="d-flex"
    >
      <Menu className="position-relative d-flex">
        <Inner className="d-flex flex-column flex-lg-wrap mt-3">
          {fields !== null &&
            fields?.map((f: any, index: number) => (
              <div className="d-flex" key={index}>
                <Item whileHover={() => setCurrentIndex(index)}>
                  <NavLink to={f?.link?.url || '/'} key={index}>
                    <Chevron className="me-2" />
                    {f?.link?.title}
                  </NavLink>
                </Item>
              </div>
            ))}
        </Inner>
        {fields![currentIndex]?.image && (
          <div className="d-lg-flex d-none">
            <AnimatePresence>
              <motion.div
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={imageAnimations}
              >
                <ImageWrapper className="ms-5">
                  <Image image={fields![currentIndex]?.image} alt="" />
                </ImageWrapper>
              </motion.div>
            </AnimatePresence>
          </div>
        )}
      </Menu>
    </StyledSubmenu>
  )
}

export default Submenu
