const capitalize = (s: string) => {
  if (typeof s !== 'string') return ''

  return s.charAt(0).toUpperCase() + s.slice(1)
}

const convertCSSKey = (key: string) => {
  const keySplit = key.split('-')
  let newKey = ''

  keySplit.forEach((value, index) => {
    let newValue = value

    if (index > 0) {
      newValue = capitalize(value)
    }

    newKey += newValue
  })

  return newKey
}

const convertCSS = (style: any) => {
  const result: any = []

  if (!style) {
    return result
  }

  const attributes = style.split(';')

  attributes.forEach((attribute: any) => {
    const { 0: key, 1: value } = attribute.split(':')

    result[convertCSSKey(key)] = value
  })

  return result
}

export default convertCSS
