/* eslint-disable react/no-array-index-key */
import React from 'react'

// Components
import NavLink from 'components/shared/NavLink'
import Submenu from 'components/layout/Header/Submenu'

// Images
import Chevron from 'img/chevrondown.inline.svg'

// Third Party
import styled, { css } from 'styled-components'
import { motion, AnimatePresence } from 'framer-motion'
import ButtonPrimary from 'components/elements/Buttons/ButtonPrimary'

const Item = styled(motion.li)<{ hasSubmenu: boolean }>`
  position: relative;
  & > div {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
  }

  & div > a {
    color: ${(props) => props.theme.color.light};
    font-size: ${({ theme }) => theme.font.size.small};

    &[aria-current] {
      font-weight: ${({ theme }) => theme.font.weight.bold};
    }

    &:hover {
      opacity: 0.7;
    }
  }

  @media (max-width: 991px) {
    padding: 7px 0;

    & div > a {
      font-size: 24px !important;
      line-height: 20px;
      font-weight: ${({ theme }) => theme.font.weight.bold};
      color: ${({ theme }) => theme.color.dark};
    }

    ${(props) =>
      props.hasSubmenu &&
      css`
        &:after {
          position: absolute;
          right: 0;
          top: 7.5px;
        }
      `}

    & svg path {
      fill: ${({ theme }) => theme.color.dark};
    }
  }
`

const HighlightedButtonPrimary = styled(ButtonPrimary)`
  @media (max-width: 1439px) {
    & > a {
      font-size: 14px;
    }

    & svg {
      display: none;
    }
  }
`

interface MenuProps {
  fields: GatsbyTypes.WpComponent
}

const Menu: React.FC<MenuProps> = ({ fields }) => {
  const [open, setOpen] = React.useState<number>(-1)

  return (
    <>
      {fields.header?.menuHeader?.map((l, index) => (
        <>
          {l?.highlighted && (
            <li className="mt-5 mt-lg-0">
              <HighlightedButtonPrimary to={l?.link?.url || '#'}>
                {l?.link?.title}
              </HighlightedButtonPrimary>
            </li>
          )}
          {!l?.highlighted && (
            <Item
              onHoverStart={() => setOpen(index)}
              onHoverEnd={() => setOpen(-1)}
              key={index}
              hasSubmenu={l?.submenu}
              className="d-flex justify-content-between"
            >
              <div>
                <NavLink to={l?.link?.url || '/'}>{l?.link?.title}</NavLink>
              </div>
              {l?.submenu && (
                <AnimatePresence>
                  {open === index && <Submenu fields={l.submenu} />}
                </AnimatePresence>
              )}
              {l?.submenu && (
                <Chevron
                  onClick={
                    open === index ? () => setOpen(-1) : () => setOpen(index)
                  }
                  className="d-lg-none"
                />
              )}
            </Item>
          )}
        </>
      ))}
    </>
  )
}

export default Menu
