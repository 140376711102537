import React from 'react'
import styled from 'styled-components'

const H1 = styled.h1`
  @media (min-width: 576px) {
    & > span {
      font-family: ${({ theme }) => theme.font.family.secondary};
      font-size: 85px;
      line-height: 86px;
    }
  }

  @media (max-width: 575px) {
    & > span {
      font-family: ${({ theme }) => theme.font.family.secondary};
      font-size: 55px;
      line-height: 56px;
    }
  }
`

export interface HeadingProps {}

const HeadingItalic: React.FC<HeadingProps> = ({ children }) => (
  <>
    <H1>{children}</H1>
  </>
)

export default HeadingItalic
