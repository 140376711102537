import React, { useState } from 'react'

// Third party
import styled, { css } from 'styled-components'
import { motion } from 'framer-motion'

// Elements
import ButtonShell from 'components/elements/Buttons/ButtonShell'

// Images
import Chevron from 'img/chevronright.inline.svg'

export const ButtonSecondaryCSS = css`
  background-color: ${({ theme }) => theme.color.secondary};
  display: inline-block;
  border: 2px solid ${(props) => props.theme.color.secondary};

  & > a,
  & > button {
    color: ${(props) => props.theme.color.light} !important;
    font-weight: ${({ theme }) => theme.font.weight.bold};
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    text-decoration: none !important;

    @media (min-width: 576px) {
      padding: 12px 17px;
    }

    @media (max-width: 575px) {
      padding: 7px 12px;
    }
  }
`

const StyledButton = styled(motion.span)`
  ${ButtonSecondaryCSS};
`

interface ButtonSecondaryProps {
  isAnchor?: boolean
  isCustom?: boolean
  target?: string
  to: string
  className?: string
}

const ButtonSecondary: React.FC<ButtonSecondaryProps> = ({
  isAnchor = false,
  isCustom = false,
  target,
  to,
  children,
  className = '',
}) => {
  const [hovered, setHovered] = useState(false)

  return (
    <StyledButton
      className={className}
      onHoverStart={() => setHovered(true)}
      onHoverEnd={() => setHovered(false)}
    >
      {isCustom ? (
        <>
          <Chevron />
          {children}
        </>
      ) : (
        <ButtonShell to={to} isAnchor={isAnchor} target={target}>
          <motion.span
            initial={{ x: -10 }}
            animate={hovered ? { x: 0 } : { x: -10 }}
          >
            <Chevron className="mb-1" />
          </motion.span>
          {children}
        </ButtonShell>
      )}
    </StyledButton>
  )
}

export default ButtonSecondary
