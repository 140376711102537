const mainTheme = {
  color: {
    primary: '#DBCCC2',
    primaryLight: '#F0E8E1',
    secondary: '#AD5F3D',
    grey: '#7C7C7B',
    dark: '#3C3C3B',
    light: '#FFFFFF',
  },

  font: {
    family: {
      primary: 'Raleway, sans-serif',
      secondary: 'Monday Routines, sans-serif',
    },

    size: {
      tiny: '10px',
      small: '14px',
      medium: '16px',
      big: '20px',
      large: '24px',
      huge: '30px',
      mega: '40px',
      giga: '60px',
    },

    weight: {
      extraLight: 200,
      light: 300,
      regular: 400,
      semiBold: 500,
      bold: 600,
      extraBold: 800,
    },
  },
}

export default mainTheme
