import React from 'react'
import styled from 'styled-components'

const Span = styled.span`
  font-family: ${({ theme }) => theme.font.family.secondary};

  @media (min-width: 576px) {
    font-size: 85px;
    line-height: 86px;
  }

  @media (max-width: 575px) {
    font-size: 55px;
    line-height: 56px;
  }
`

export interface HeadingProps {}

const SpanItalic: React.FC<HeadingProps> = ({ children }) => (
  <>
    <Span>{children}</Span>
  </>
)

export default SpanItalic
